import { Customer, CustomerApi, CustomerFormData, CustomerFormDataApi, CustomerFormErrors, CustomerFormErrorsApi } from '../types';
import { Constants } from '../../../shared/constants';
import moment from 'moment';

export class CustomerMappers {
  public static fromApi(customer: CustomerApi): Customer {
    return {
      id: customer.id,
      firstname: customer.name,
      lastname: customer.surname,
      patronymic: customer.patronymic,
      regionId: customer.region_id,
      nationality: customer.nationality,
      regionName: customer.region_name || '',
      passport: { secret: customer.pin_number },
      status: { id: customer.state_id, name: customer.state_name },
      phoneNumber: customer.number,
      email: customer.email,
      gender: customer.gender,
      insuranceNumber: customer.insurance_number,
      franchise: customer.franchise,
      birthDate: customer.birth_date,
      description: customer.descr,
      createdAt: customer.created_at,
    };
  }

  public static manyFromApi(customer: CustomerApi[]): Customer[] {
    return customer.map((item) => this.fromApi(item));
  }

  public static formDataToApi(formData: CustomerFormData): CustomerFormDataApi {
    return {
      client_id: formData.id,
      name: formData.firstname,
      surname: formData.lastname,
      patronymic: formData.patronymic,
      number: '994' + formData.phoneNumber,
      nationality: formData.nationality,
      region_id: formData.regionId,
      state_id: formData.statusId,
      pin_number: formData.passportSecret,
      gender: formData.gender,
      insurance_number: formData.insuranceNumber,
      franchise: formData.franchise,
      birth_date: formData.birthDate ? formData.birthDate.format(Constants.DATE) : null,
      email: formData.email,
      descr: formData.description,
    };
  }
  public static formErrorsFromApi(errors: CustomerFormErrorsApi): CustomerFormErrors {
    return {
      id: errors.client_id?.join(', '),
      firstname: errors.name?.join(', '),
      lastname: errors.surname?.join(', '),
      patronymic: errors.patronymic?.join(', '),
      phoneNumber: errors.number?.join(', '),
      gender: errors.gender?.join(', '),
      insuranceNumber: errors.insurance_number?.join(', '),
      franchise: errors.franchise?.join(', '),
      birthDate: errors.birth_date?.join(', '),
      email: errors.email?.join(', '),
      nationality: errors.nationality?.join(', '),
      regionId: errors.region_id?.join(', '),
      passportSecret: errors.pin_number?.join(', '),
      statusId: errors.state_id?.join(', '),
      description: errors.descr?.join(', '),
      _custom: errors._custom?.join(', '),
    };
  }
  public static formInitializer(customer: Customer): CustomerFormData {
    return {
      id: customer.id.toString(),
      firstname: customer.firstname,
      lastname: customer.lastname,
      phoneNumber: customer.phoneNumber.replace('994', '').replace('+', ''),
      patronymic: customer.patronymic,
      birthDate: customer.birthDate ? moment(customer.birthDate, Constants.DATE) : null,
      gender: customer.gender,
      regionId: customer.regionId?.toString(),
      insuranceNumber: customer.insuranceNumber ? customer.insuranceNumber.toString() : '',
      franchise: customer.franchise ? customer.franchise.toString() : '',
      passportSecret: customer.passport.secret,
      description: customer.description,
      statusId: customer.status.id.toString(),
      nationality: customer.nationality,
      email: customer.email || '',
    };
  }
}
