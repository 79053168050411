import { Withdraw, WithdrawApi } from '../types/withdraw';

export class CashRegisterWithdrawsMapper {
  public static oneFromApi(deposit: WithdrawApi): Withdraw {
    return {
      id: deposit.id,
      user: { id: 0, name: deposit.user_name },
      message: deposit.message,
      createdAt: deposit.created_at,
    };
  }

  public static manyFromApi(deposits: WithdrawApi[]): Withdraw[] {
    return deposits.map(deposit => this.oneFromApi(deposit));
  }
}
