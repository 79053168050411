import { ApiResponse } from '../../../shared/utils/api-response';
import { urlMaker } from '../../../shared/utils/url-maker';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';
import { caller } from '../../../shared/utils/caller';

import { CashRegisterMapper } from '../mappers';
import { CashRegisterSaleFormDataApi, CashRegisterSalesFormErrors } from '../types/sale';

export class CashRegisterSalesService {
  public static async create(
    values: CashRegisterSaleFormDataApi,
  ): Promise<ApiResponse<200> | ApiResponse<422, CashRegisterSalesFormErrors> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/manual_sale');
    const body = new FormData();

    appendToFormData<CashRegisterSaleFormDataApi>(CashRegisterMapper.Sales.formDataToApi(values), body);

    try {
      const response = await caller(url, { body, method: 'POST' });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else if (response.status === 400) {
        const { errors } = await response.json();

        return new ApiResponse(
          400,
          Object.values(errors)
            .flat()
            .join(', '),
          null,
        );
      } else if (response.status === 422) {
        const { errors } = await response.json();

        return new ApiResponse(422, CashRegisterMapper.Sales.formErrorsFromApi(errors), null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
}
