import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import azAZ from 'antd/lib/locale/az_AZ';
import { MainRouter } from './router';
import { MeProvider } from './modules/me/context/context';
import { StyledAntdGlobal } from './shared/modules/antd/styled/global';
import { BrowserRouter } from 'react-router-dom';
import { EmergencyProvider } from './modules/settings/context';

export const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } });

const App = () => {
  return (
    <ConfigProvider locale={azAZ}>
      <QueryClientProvider client={queryClient}>
        <MeProvider>
          <EmergencyProvider>
            <StyledAntdGlobal />
            <BrowserRouter>
              <MainRouter />
            </BrowserRouter>
          </EmergencyProvider>
        </MeProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default App;
