import React, { useCallback, useContext } from 'react';
import { Avatar, Dropdown, Menu } from 'antd';
import * as Icons from '@ant-design/icons';
import { useQuery } from 'react-query';
import { blue, grey } from '@ant-design/colors';

import { StyledHeader, StyledMenuFoldIcon, StyledMenuUnfoldIcon } from '../styled';
import { useHeader } from '../hooks';
import { StyledHeaderButton, StyledHeaderLeft, StyledHeaderRight, StyledPortalArea } from '../styled/header';
import { CurrentCashRegister } from '../../cash-register/containers/current-device';
import { MeContext } from '../../me/context/context';
import { CashboxesService } from '../../cash-flow/cashboxes/services';
import { EmergencyContext } from '../../settings/context';

export const AppHeader = () => {
  const { can, state } = useContext(MeContext);
  const { toggleSidebar, sidebarIsOpen, avatarText, logout } = useHeader();
  const { setEmergencyMode } = useContext(EmergencyContext)

  const handleEmergency = useCallback(() => {
    setEmergencyMode()
  }, [setEmergencyMode])


  const cashboxBalance = useQuery('cashbox-balance', async ({ queryKey }) => {
    const result = await CashboxesService.getCurrentBalance();

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(result.data);
    }
  });

  return (
    <StyledHeader $wide={!sidebarIsOpen}>
      <StyledHeaderLeft>
        {sidebarIsOpen ? <StyledMenuFoldIcon onClick={toggleSidebar} /> : <StyledMenuUnfoldIcon onClick={toggleSidebar} />}
      </StyledHeaderLeft>
      <StyledPortalArea />
      <StyledHeaderRight>
        {can(97) && can(98) && <CurrentCashRegister />}
        <Dropdown
          overlay={
            <Menu>
              {cashboxBalance.data && (
                <Menu.Item disabled icon={<Icons.MoneyCollectOutlined />} style={{ color: 'black' }}>
                  {cashboxBalance.data.name}: {cashboxBalance.data.amount} AZN
                </Menu.Item>
              )}
              <Menu.Item onClick={logout} icon={<Icons.LogoutOutlined />}>
                Çıxış
              </Menu.Item>
            </Menu>
          }
        >
          <StyledHeaderButton onDoubleClick={handleEmergency}>
            <Avatar style={{ backgroundColor: !!state.user?.data?.emergencyMode ? blue[7] : grey[1] }}>{avatarText}</Avatar>
          </StyledHeaderButton>
        </Dropdown>
      </StyledHeaderRight>
    </StyledHeader>
  );
};
