import React, { useContext } from "react";
import { SettingsService } from "../services";
import { ContextType } from "./types";
import { initialState } from "./state";
import { MeContext } from "../../me/context/context";

export const EmergencyContext = React.createContext<ContextType>(
    {
        state: initialState,
        setEmergencyMode: () => { }
    }
);

export const EmergencyProvider: React.FC = ({ children }) => {
    const { state } = useContext(MeContext);

    const [emergencyMode, setEmergencyMode] = React.useState<boolean>(() => !!state.user.data?.emergencyMode);

    const sendApiRequest = React.useCallback(async (mode: number) => {
        try {
            const response = await SettingsService.activation(mode);
            if (response.status === 200) window.location.reload()
        } catch (error) {
            console.error("Error updating emergency mode:", error);
        }
    }, []);

    const toggleEmergencyMode = React.useCallback(() => {
        const newMode = !state.user.data?.emergencyMode;
        setEmergencyMode(newMode);
        sendApiRequest(newMode ? 1 : 0);
    }, [state.user, sendApiRequest]);

    const contextValue: ContextType = {
        state: { emergencyMode },
        setEmergencyMode: toggleEmergencyMode
    };

    return (
        <EmergencyContext.Provider value={contextValue}>
            {children}
        </EmergencyContext.Provider>
    );
};
