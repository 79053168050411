import { Deposit, DepositApi } from '../types/deposit';

export class CashRegisterDepositsMapper {
  public static oneFromApi(deposit: DepositApi): Deposit {
    return {
      id: deposit.id,
      user: { id: 0, name: deposit.user_name },
      message: deposit.message,
      createdAt: deposit.created_at,
    };
  }

  public static manyFromApi(deposits: DepositApi[]): Deposit[] {
    return deposits.map(deposit => this.oneFromApi(deposit));
  }
}
