import { message } from 'antd';
import { handlebars } from 'hbs';

import { getBase64 } from '../../../shared/utils/get-base-64';
import logo from '../../../shared/assets/logo.png';

//@ts-ignore
import documentTemplate from '../templates/document.hbs';
import { AppointmentsService } from '../services';
import { AppointmentMappers } from '../mappers';

handlebars.registerHelper('inc', function (value) {
  return parseInt(value) + 1;
});

export const printAppointmentDocumentUseCase = async (id: string | number) => {
  const key = Math.round(Math.random() * 1000000).toString();
  message.loading({ key, content: 'Sənəd hazırlanır.' });

  try {
    const template = await fetch(documentTemplate).then((r) => r.text());
    const logoBlob = await fetch(logo).then((r) => r.blob());
    const logoBase64 = await getBase64(logoBlob);

    const result = await AppointmentsService.getOneById(id);
    const queues = await AppointmentsService.getQueues(id);

    if (result.status !== 200) {
      message.error({ key, content: result.data });
      return false;
    }

    if (queues.status !== 200) {
      message.error({ key, content: queues.data });
      return false;
    }

    const mappedData = {
      appointment: AppointmentMappers.toPrint(result.data, queues.data),
      logo: logoBase64,
    };
    const renderedTemplate = handlebars.compile(template)(mappedData);

    const printWindow = window.open();

    if (printWindow) {
      printWindow.document.write(renderedTemplate);
      printWindow.document.close();
      printWindow.print();
      printWindow.onfocus = () => {
        setTimeout(() => printWindow.close(), 0);
      };
    } else {
      message.error({ key, content: 'Brauzerdə xəta baş verdi.' });
      return false;
    }
  } catch (e) {
    message.error({ key, content: 'Şablon əldə edilə bilmədi.' });
  }
};
