import { caller } from '../../../shared/utils/caller';
import { urlMaker } from '../../../shared/utils/url-maker';
import { ApiResponse } from '../../../shared/utils/api-response';

export class SettingsService {
  public static async activation(mode: string | number): Promise<ApiResponse<200, any> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/settings/emergmode', { emergency_mode: mode });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        return new ApiResponse(200, data, null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
}
