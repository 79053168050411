import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Column } from 'react-table';
import { Button, Dropdown, Menu, message, Modal } from 'antd';
import * as Icons from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { NextTableContext } from '../../../shared/modules/next-table/types';
import { nextTableColumns } from '../../../shared/modules/next-table/helpers/next-table-columns';
import { useSearchParams } from '../../../shared/hooks/use-search-params';

import { AppointmentsTableContext } from '../context';
import { Appointment } from '../types';
import { localURLMaker } from '../../../shared/utils/url-maker';
import { StopPropagation } from '../../../shared/components/stop-propagation';
import { AppointmentsService } from '../services';
import { useStatusList } from '../../status/hooks/use-list';
import { printAppointmentDocumentUseCase } from '../use-cases/print-document';
import { MeContext } from '../../me/context/context';
import { CashRegisterStatusContext } from '../../cash-register/context/status';

export const useAppointmentsTable = ({ limited = false }) => {
  const { handleFetch } = useContext<NextTableContext>(AppointmentsTableContext);
  const history = useHistory();
  const statusList = useStatusList({ modelId: 13 });
  const { can } = useContext(MeContext);
  const cashRegisterStatus = useContext(CashRegisterStatusContext);

  const getRowProps = useCallback(
    (id) => ({
      onDoubleClick: () => history.push(localURLMaker('/appointments/:id/details', { id })),
    }),
    [history],
  );

  const columns = useMemo<Column<Appointment>[]>(
    () => [
      {
        ...nextTableColumns.actions,
        Cell: ({ row: { original } }) => {
          const goTo = useCallback(
            (type: string) => {
              history.push(localURLMaker('/appointments/:id/:type', { id: original.id, type }));
            },
            [original.id],
          );

          const goToUpdate = useCallback(() => {
            history.push(
              localURLMaker('/appointments/:id/update/:userId', {
                id: original.id,
                userId: original.customer.id,
              }),
            );
          }, [original.customer.id, original.id]);

          const handlePrintCheck = useCallback(async () => {
            if (!cashRegisterStatus.data?.connected) {
              message.error('Kassa əməliyyatları üçün ekranın yuxarı hissəsində olan "Obyekt seç" düyməsindən istifadə etməklə obyektinizi seçin');
              return false;
            }

            const action = async () => {
              const result = await AppointmentsService.print(original.id);

              if (result.status === 200) {
                message.success('Çap edildi.');
              } else {
                message.error(result.data);
              }
            };

            Modal.confirm({
              title: 'Çap et',
              content: 'Qəbulu çap etməyə əminsinizmi?',
              onOk: action,
            });
          }, [original.id]);

          const handlePrintDocument = useCallback(() => {
            return printAppointmentDocumentUseCase(original.id);
          }, [original.id]);

          const handleAction = useCallback(
            async (actionType) => {
              const handler = async () => {
                message.loading({ key: 'message', content: 'Əməliyyat aparılır.' });
                const result = await AppointmentsService.actionByIds([original.id], actionType);

                if (result.status === 200) {
                  message.success({ key: 'message', content: 'Əməliyyat müvəffəqiyyətlə başa çatdı' });
                  history.push(localURLMaker('/appointments', {}, { reFetchAppointmentsTable: true }));
                } else {
                  message.error({ key: 'message', content: result.data as string });
                }
              };

              Modal.confirm({
                title: 'Əməliyyatı etməyinizə əminsinizmi?',
                content: 'Əməliyyatı etdikdən sonra məlumatların geri qaytarılması mümkün olmayacaq.',
                onOk: handler,
              });
            },
            [original.id],
          );

          const handleUpdateState = useCallback(
            async (stateId: number | string) => {
              const handler = async () => {
                message.loading({ key: 'message', content: 'Əməliyyat aparılır.' });
                const result = await AppointmentsService.updateStatusByIds([original.id], stateId);

                if (result.status === 200) {
                  message.success({ key: 'message', content: 'Əməliyyat müvəffəqiyyətlə başa çatdı' });
                  history.push(localURLMaker('/appointments', {}, { reFetchAppointmentsTable: true }));
                } else {
                  message.error({ key: 'message', content: result.data as string });
                }
              };

              Modal.confirm({
                title: 'Status dəyişməyə əminsinizmi?',
                content: 'Status dəyişdikdən sonra məlumatların geri qaytarılması mümkün olmayacaq.',
                onOk: handler,
              });
            },
            [original.id],
          );

          const overlay = (
            <Menu>
              {can(64) && (
                <Menu.Item onClick={() => goTo('details')} icon={<Icons.FileTextOutlined />}>
                  Ətraflı
                </Menu.Item>
              )}
              {can(66) && (
                <Menu.Item onClick={goToUpdate} icon={<Icons.EditOutlined />}>
                  Dəyişdir
                </Menu.Item>
              )}
              <Menu.Divider />
              {can(90) && (
                <Menu.Item disabled={original.status.id !== 12} onClick={handlePrintCheck} icon={<Icons.PrinterOutlined />}>
                  Çeki çap et
                </Menu.Item>
              )}
              {can(70) && (
                <Menu.Item onClick={handlePrintDocument} icon={<Icons.PrinterOutlined />}>
                  Qəbul sənədini çap et
                </Menu.Item>
              )}
              <Menu.Divider />
              {can(68) && (
                <Menu.SubMenu title='Status dəyiş' icon={<Icons.AppstoreOutlined />}>
                  {statusList.data.map((status) => (
                    <Menu.Item key={status.id} onClick={() => handleUpdateState(status.id)}>
                      {status.name}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              )}
              <Menu.Divider />
              {can(69) && (
                <Menu.Item onClick={() => handleAction('remove')} icon={<Icons.DeleteOutlined />}>
                  Sil
                </Menu.Item>
              )}
            </Menu>
          );

          return (
            <StopPropagation>
              <Dropdown overlay={overlay}>
                <Button size='small' icon={<Icons.MoreOutlined />} />
              </Dropdown>
            </StopPropagation>
          );
        },
      },
      {
        ...nextTableColumns.smaller,
        accessor: (row) => row.id,
        id: 'id',
        Header: 'Kod',
      },
      {
        ...nextTableColumns.smaller,
        accessor: (row) => row.customer.id,
        sortable: !limited,
        filterable: !limited,
        id: 'client_id',
        Header: 'Müştəri kodu',
      },
      {
        accessor: (row) => row.customer.fullName,
        sortable: !limited,
        filterable: !limited,
        id: 'client_name',
        Header: 'Müştəri',
      },
      {
        ...nextTableColumns.smaller,
        accessor: (row) => row.customer.gender,
        id: 'gender',
        Header: 'Cins',
        Cell: ({ cell: { value } }) => (value === 'female' ? 'Qadın' : value === 'male' ? 'Kişi' : null),
      },
      {
        accessor: (row) => row.customer.phoneNumber,
        id: 'number',
        Header: 'Telefon nömrəsi',
      },
      {
        ...nextTableColumns.normal,
        accessor: (row) => row.status.name,
        id: 'state_name',
        Header: 'Status',
      },
      {
        ...nextTableColumns.small,
        accessor: (row) => row.type,
        id: 'type',
        Header: 'Tipi',
        Cell: ({ cell: { value } }) => (value === 'outpatient' ? 'Ambulator' : 'Göndəriş'),
      },
      {
        ...nextTableColumns.date,
        accessor: (row) => row.scheduledAt,
        id: 'visit_date',
        Header: 'Qəbul tarixi',
      },
      {
        ...nextTableColumns.date,
        accessor: (row) => row.createdAt,
        id: 'created_at',
        Header: 'Yaranma tarixi',
      },
    ],
    [can, cashRegisterStatus.data?.connected, history, limited, statusList.data],
  );

  const { searchParams, remove } = useSearchParams<{ reFetchAppointmentsTable?: string }>();

  useEffect(() => {
    (async () => {
      if (searchParams.reFetchAppointmentsTable) {
        remove.current('reFetchAppointmentsTable');
        await handleFetch();
      }
    })();
  }, [handleFetch, remove, searchParams.reFetchAppointmentsTable]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return { columns, getRowProps };
};
