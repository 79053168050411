import { localURLMaker, urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { ApiResponse } from '../../../shared/utils/api-response';
import { Appointment, AppointmentDetails, AppointmentFormData, AppointmentFormDataApi, AppointmentFormErrors, AppointmentQueue } from '../types';
import { AppointmentMappers } from '../mappers';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';
import { formDataFlat } from '../../../shared/utils/form-data-flat';
import { nextTableMetaMapper } from '../../../shared/modules/next-table/utils/meta-mapper';
import { NextTableMetaResponse } from '../../../shared/modules/next-table/types';

export class AppointmentsService {
  public static async getOneById(id: string | number): Promise<ApiResponse<200, AppointmentDetails> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/visits/info', { visit_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        return new ApiResponse(200, AppointmentMappers.detailsFromApi(data), null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async getAll(
    query: Record<string, any>,
  ): Promise<ApiResponse<200, Appointment[], NextTableMetaResponse> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/visits', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data, ...meta } = await response.json();
        return new ApiResponse(200, AppointmentMappers.manyFromApi(data), nextTableMetaMapper(meta));
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async createOrUpdate(
    values: AppointmentFormData,
    type: string = 'create',
  ): Promise<ApiResponse<200, { id: number }> | ApiResponse<422, AppointmentFormErrors> | ApiResponse<400 | 500, string>> {
    const url = type === 'create' ? urlMaker('/api/visits/create') : urlMaker('/api/visits/edit');
    const body = new FormData();

    appendToFormData<AppointmentFormDataApi>(AppointmentMappers.formDataToApi(values), body);

    try {
      const response = await caller(url, { body, method: 'POST' });

      if (response.ok) {
        const { data } = await response.json();

        return new ApiResponse(200, { id: data }, null);
      } else if (response.status === 422) {
        const { errors } = await response.json();

        return new ApiResponse(422, AppointmentMappers.formErrorsFromApi(errors), null);
      } else if (response.status === 400) {
        const { errors } = await response.json();
        if (errors.insurance_number_not_found) {
          return new ApiResponse(400, 'Sığorta nömrəsi tapılmadı', null);
        } else if (errors.not_enoght_amount) {
          return new ApiResponse(400, 'Kassada kifayət qədər məbləğ yoxdur', null);
        }

        return new ApiResponse(400, Object.values(errors).join('. '), null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async actionByIds(ids: (string | number)[], action: string): Promise<ApiResponse<200> | ApiResponse<400 | 500, string>> {
    const url = urlMaker(localURLMaker('/api/visits/:action', { action }));
    const body = new FormData();

    appendToFormData(formDataFlat({ visit_id: ids }), body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else if (response.status === 400) {
        const { errors } = await response.json();
        if (errors.not_enoght_amount) {
          return new ApiResponse(400, 'Kassada kifayət qədər məbləğ yoxdur', null);
        }

        return new ApiResponse(400, Object.values(errors).join('. '), null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async updateStatusByIds(
    ids: (string | number)[],
    stateId: number | string,
  ): Promise<ApiResponse<200> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/visits/state');
    const body = new FormData();

    appendToFormData(formDataFlat({ visit_id: ids, state_id: stateId }), body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async print(id: number | string): Promise<ApiResponse<200> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/sale', { visit_id: id, currency: 'AZN' });

    try {
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else if (response.status === 422 || response.status === 400) {
        const { errors } = await response.json();
        const mappedErrors = Object.values<string[]>(errors).flat().join(', ');

        return new ApiResponse(400, mappedErrors, null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async getQueues(id: number | string): Promise<ApiResponse<200, AppointmentQueue[]> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/visits/queue', { visit_id: id });

    try {
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        const { data } = await response.json();
        const mappedData = AppointmentMappers.queuesFromApi(data);

        return new ApiResponse(200, mappedData, null);
      } else if (response.status === 422 || response.status === 400) {
        const { errors } = await response.json();
        const mappedErrors = Object.values<string[]>(errors).flat().join(', ');

        return new ApiResponse(400, mappedErrors, null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async getInsuranceExcel(): Promise<ApiResponse<200, any> | ApiResponse<400 | 500, string>> {
    try {
      const url = urlMaker('/api/visits/insurance_export');
      const response = await caller(url);
      if (response.ok) {
        const blob = await response.blob();
        return new ApiResponse(200, blob, null);
      } else {
        return new ApiResponse(500, 'Əməliyyat aparıla bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Əməliyyat aparıla bilmədi', null);
    }
  }

  public static async exportExcel(query: Record<string, any>): Promise<ApiResponse<200, any> | ApiResponse<400 | 500, string>> {
    try {
      const url = urlMaker('/api/visits/export', query);
      const response = await caller(url);
      if (response.ok) {
        const blob = await response.blob();
        return new ApiResponse(200, blob, null);
      } else {
        if (response.status === 400 || response.status === 422) {
          const { errors } = await response.json();
          const message = Object.values(errors).flat().join(', ');
          return new ApiResponse(500, message || 'Əməliyyat aparıla bilmədi', null);
        }
        return new ApiResponse(500, 'Əməliyyat aparıla bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Əməliyyat aparıla bilmədi', null);
    }
  }
}
