import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, Col, Form, Radio, Row, Select, Spin, Typography } from 'antd';

import { FormAlert } from '../../../shared/modules/form/styled';

import { useCustomerForm } from '../hooks/use-form';
import { CustomerFormData } from '../types';
import { FormOptions, FormType } from '../../../shared/modules/form/types';
import { TextField } from '../../../shared/modules/form/fields/text';
import { DateField } from '../../../shared/modules/form/fields/date';
import { RadioField } from '../../../shared/modules/form/fields/radio';
import { SelectField } from '../../../shared/modules/form/fields/select';
import { useStatusList } from '../../status/hooks/use-list';
import { useDebounceEffect } from '../../../shared/hooks/use-debounce-effect';
import { CustomersService } from '../services';
import { useGetRegions } from '../../regions/hooks';

type FormProps = FormikProps<CustomerFormData> & { type: FormType };

const FormikComponent = memo<FormProps>(({ isSubmitting, handleSubmit, status, values }) => {
  const [similarUsersCount, setSimilarUsersCount] = useState(0);
  const statusCollection = useStatusList({ modelId: 7 });

  const regions = useGetRegions();

  const gendersNode = useMemo(
    () => [
      <Radio key='male' value='male'>
        Kişi
      </Radio>,
      <Radio key='female' value='female'>
        Qadın
      </Radio>,
    ],
    [],
  );

  const statusOptions = useMemo(
    () =>
      statusCollection.data.map((status) => (
        <Select.Option key={status.id} value={status.id.toString()}>
          {status.name}
        </Select.Option>
      )),
    [statusCollection.data],
  );

  useDebounceEffect(
    useCallback(async () => {
      if (!!values.firstname || !!values.lastname || !!values.patronymic) {
        const result = await CustomersService.getAll({ name: values.firstname, surname: values.lastname, patronymic: values.patronymic });
        setSimilarUsersCount(result.meta?.total || 0);
      }
    }, [values.firstname, values.lastname, values.patronymic]),
    1000,
  );

  return (
    <Form layout='vertical'>
      <FormAlert $visible={!!status} message={status?.message} type={status?.type} />
      <Row gutter={[24, 0]}>
        <Col xs={24} md={12}>
          <TextField name='firstname' item={{ label: 'Ad' }} />
        </Col>
        <Col xs={24} md={12}>
          <TextField name='lastname' item={{ label: 'Soyad' }} />
        </Col>
        <Col xs={24} md={12}>
          <TextField name='patronymic' item={{ label: 'Ata adı' }} />
        </Col>
        <Col xs={24} md={12}>
          <SelectField name='nationality' item={{ label: 'Milliyət' }}>
            <Select.Option value='Azərbaycanlı'>Azərbaycanlı</Select.Option>
            <Select.Option value='Türk'>Türk</Select.Option>
            <Select.Option value='Rus'>Rus</Select.Option>
            <Select.Option value='Gürcü'>Gürcü</Select.Option>
            <Select.Option value='Digər'>Digər</Select.Option>
          </SelectField>
        </Col>
        <Col xs={24} md={12}>
          <TextField name='passportSecret' item={{ label: 'FİN Kod' }} />
        </Col>
        <Col xs={24} md={12}>
          <TextField name='phoneNumber' item={{ label: 'Telefon nömrəsi' }} input={{ addonBefore: '+994' }} />
        </Col>
        <Col xs={24} md={12}>
          <DateField name='birthDate' item={{ label: 'Təvəllüd' }} />
        </Col>
        <Col xs={24} md={12}>
          <TextField name='email' item={{ label: 'Email' }} />
        </Col>
        <Col xs={24} md={12}>
          <SelectField name='statusId' item={{ label: 'Status' }} input={{ loading: statusCollection.loading }}>
            {statusOptions}
          </SelectField>
        </Col>
        <Col xs={24} md={12}>
          <RadioField name='gender' item={{ label: 'Cins' }}>
            {gendersNode}
          </RadioField>
        </Col>
        <Col xs={24} md={12}>
          <TextField name='insuranceNumber' format='decimal' item={{ label: 'Sığorta nömrəsi' }} />
        </Col>
        <Col xs={24} md={12}>
          <TextField name='franchise' format='decimal' item={{ label: 'Franşiza' }} input={{ addonAfter: '%' }} />
        </Col>
        <Col xs={24} md={12}>
          <SelectField name='regionId' item={{ label: 'Yaşadığı ərazi' }} input={{ placeholder: 'Yaşadığı ərazini seçin...' }}>
            {regions.data?.map(elem => <Select.Option value={elem.id.toString()} key={elem.id}>
              {elem.name}
            </Select.Option>)}
          </SelectField>
        </Col>
        <Col xs={24}>
          <Typography.Paragraph type={!similarUsersCount ? 'success' : 'danger'}>
            {!similarUsersCount ? 'Oxşar müştəri tapılmadı' : `Daxil edilən məlumatlara oxşar ${similarUsersCount} müştəri tapıldı`}
          </Typography.Paragraph>
        </Col>
      </Row>
      <Button onClick={() => handleSubmit()} type='primary' loading={isSubmitting} block={true}>
        Təsdiqlə
      </Button>
    </Form>
  );
});

export const CustomerForm: FC<FormOptions> = (props) => {
  const { initialValues, ...form } = useCustomerForm(props);

  return (
    <Spin spinning={initialValues.loading}>
      <Formik {...form} enableReinitialize={true} initialValues={initialValues.data}>
        {(formikProps) => <FormikComponent type={props.type} {...formikProps} />}
      </Formik>
    </Spin>
  );
};
