import React, { memo, useCallback, useMemo } from 'react';
import { Form, Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { FormItemProps } from 'antd/es/form';
import { FieldConfig, FieldInputProps, FieldMetaProps, useField } from 'formik';
import { twoLevelShallowEqualObject } from '../../../utils/two-level-shallow-equal-object';

export type SelectFieldProps = FieldConfig & { item?: FormItemProps; input?: SelectProps<any> };
export type MemoizedSelectFieldProps = {
  item?: FormItemProps;
  input?: SelectProps<any>;
  field: FieldInputProps<any>;
  meta: FieldMetaProps<any>;
  children: any;
};

export const MemoizedSelectField = memo<MemoizedSelectFieldProps>(({ item, input, field, meta, children }) => {
  const filterOption = useCallback((input, option) => {
    if (option.key.includes('__RC_SELECT')) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    if (option.children instanceof Array) {
      return option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0;
    } else {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  }, []);

  return (
    <Form.Item validateStatus={meta.touched && !!meta.error ? 'error' : undefined} help={meta.error} {...item}>
      <Select id={field.name} allowClear={true} showSearch={true} filterOption={filterOption} {...field} {...input}>
        {children}
      </Select>
    </Form.Item>
  );
}, twoLevelShallowEqualObject);

export const SelectField = memo<SelectFieldProps>(({ item, input, children, ...props }) => {
  const [{ onChange: baseOnChange, ...field }, meta] = useField(props);

  const value = useMemo(() => field.value || null, [field.value]);

  const onChange = useCallback(
    (value) => {
      baseOnChange(field.name)({ target: { value: value || '' } } as any);
    },
    [baseOnChange, field.name],
  );

  const newField = useMemo(() => ({ ...field, value: value || undefined, onChange }), [field, onChange, value]);

  return (
    <MemoizedSelectField field={newField} meta={meta} input={input} item={item}>
      {children}
    </MemoizedSelectField>
  );
});
