import { localURLMaker, urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { ApiResponse } from '../../../shared/utils/api-response';
import { Customer, CustomerFormData, CustomerFormDataApi, CustomerFormErrors } from '../types';
import { CustomerMappers } from '../mappers';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';
import { formDataFlat } from '../../../shared/utils/form-data-flat';
import { nextTableMetaMapper } from '../../../shared/modules/next-table/utils/meta-mapper';
import { NextTableMetaResponse } from '../../../shared/modules/next-table/types';

export class CustomersService {
  public static async getOneById(id: string | number): Promise<ApiResponse<200, Customer> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/clients/info', { client_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        return new ApiResponse(200, CustomerMappers.fromApi(data), null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async getOneByPassportSecret(passportSecret: string): Promise<ApiResponse<200, Customer> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/clients/search', { pin_number: passportSecret });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        return new ApiResponse(200, CustomerMappers.fromApi(data), null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async getAll(
    query: Record<string, any>,
  ): Promise<ApiResponse<200, Customer[], NextTableMetaResponse> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/clients', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data, ...meta } = await response.json();
        return new ApiResponse(200, CustomerMappers.manyFromApi(data), nextTableMetaMapper(meta));
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async createOrUpdate(
    values: CustomerFormData,
    type: string = 'create',
  ): Promise<ApiResponse<200, { id: number }> | ApiResponse<422, CustomerFormErrors> | ApiResponse<400 | 500, string>> {
    const url = type === 'create' ? urlMaker('/api/clients/create') : urlMaker('/api/clients/edit');
    const body = new FormData();

    appendToFormData<CustomerFormDataApi>(CustomerMappers.formDataToApi(values), body);

    try {
      const response = await caller(url, { body, method: 'POST' });

      if (response.ok) {
        const { data: id } = await response.json();
        return new ApiResponse(200, { id }, null);
      } else if (response.status === 422) {
        const { errors } = await response.json();
        return new ApiResponse(422, CustomerMappers.formErrorsFromApi(errors), null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async actionByIds(ids: (string | number)[], action: string): Promise<ApiResponse<200> | ApiResponse<400 | 500, string>> {
    const url = urlMaker(localURLMaker('/api/clients/:action', { action }));
    const body = new FormData();

    appendToFormData(formDataFlat({ client_id: ids }), body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async combine(parentId: string | number, childIds: (string | number)[]): Promise<ApiResponse<200> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/clients/combine', { parent_client_id: parentId, child_client_id: childIds });

    try {
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        return new ApiResponse(400, Object.values(errors).join('. '), null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async exportNumbers(filters: object): Promise<ApiResponse<200, { name: string; file: Blob }> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/clients/export', filters);

    try {
      const response = await caller(url);

      if (response.status === 200) {
        const file = await response.blob();
        const disposition = response.headers.get('Content-Disposition') || '';

        const name = disposition.split('; ')[1].replace('filename=', '').split('"')[1];

        return new ApiResponse(200, { name, file }, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        return new ApiResponse(400, Object.values(errors).join('. '), null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
}
