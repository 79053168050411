import { Status, StatusApi, StatusFormData, StatusFormDataApi, StatusFormErrors, StatusFormErrorsApi } from '../types';

export class StatusMappers {
  public static fromApi(raw: StatusApi): Status {
    return {
      id: raw.id,
      name: raw.state_name,
      description: raw.descr,
      model: { id: raw.model_id, name: raw.model_name },
    };
  }

  public static manyFromApi(raw: StatusApi[]): Status[] {
    return raw.map(item => StatusMappers.fromApi(item));
  }

  public static formDataToApi(formData: StatusFormData): StatusFormDataApi {
    return {
      state_id: formData.id,
      state_name: formData.name,
      model_id: formData.modelId,
      descr: formData.description,
    };
  }

  public static formErrorsFromApi(errors: StatusFormErrorsApi): StatusFormErrors {
    return {
      id: errors.state_id,
      name: errors.state_name,
      modelId: errors.model_id,
      description: errors.descr,
      _custom: errors._custom,
    };
  }

  public static formInitializer(status: Status): StatusFormData {
    return {
      id: status.id.toString(),
      name: status.name,
      modelId: status.model.id.toString(),
      description: status.description || '',
    };
  }
}
