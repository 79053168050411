import { useCallback, useEffect, useRef, useState } from 'react';
import { UseQueryOptions } from '../types';

export const useQuery = <T = any>(query, { initialData, enabled = true, fetchOnce, polling }: UseQueryOptions = {}) => {
  const initialDataRef = useRef(initialData);
  const fetchedOnceRef = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<T>(initialDataRef.current);
  const [error, setError] = useState<string | null>(null);
  const mounted = useRef<boolean>(true);

  const fetch = useCallback(
    async (disableLoading?: boolean) => {
      disableLoading || setLoading(true);
      if (mounted.current) {
        setError(null);
      }
      try {
        const data = await query();
        if (mounted.current) {
          setData(data);
        }
      } catch (e: any) {
        if (mounted.current) {
          setError(e.message);
        }
      } finally {
        if (mounted.current) {
          disableLoading || setLoading(false);
        }
      }
    },
    [query],
  );

  const reset = useCallback(() => {
    if (mounted.current) {
      setError(null);
      setData(initialDataRef.current);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (enabled && !(fetchOnce && fetchedOnceRef.current)) {
        fetchedOnceRef.current = true;
        await fetch();
      }
    })();

    if (polling) {
      const interval = setInterval(async () => {
        if (enabled && !(fetchOnce && fetchedOnceRef.current)) {
          fetchedOnceRef.current = true;
          await fetch();
        }
      }, polling);

      return () => {
        clearInterval(interval);
      };
    }
  }, [enabled, fetch, fetchOnce, polling]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return { data, loading, error, reset, reFetch: fetch };
};
