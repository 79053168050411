import { ApiResponse } from '../../../shared/utils/api-response';
import { caller } from '../../../shared/utils/caller';
import { urlMaker } from '../../../shared/utils/url-maker';
import { IRegion } from '../types';

export class RegionsService {
  public static async getAll(query: Record<string, any>): Promise<ApiResponse<200, IRegion[], any> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/regions', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        return new ApiResponse(200, data, undefined);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      console.log(e);
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }
}
