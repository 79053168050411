import styled from 'styled-components';
import { Layout } from 'antd';

export const StyledSider = styled(Layout.Sider).attrs({ theme: 'light' })`
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 2;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);

  .ant-menu-inline-collapsed {
    width: 46px;

    .ant-menu-item-group > .ant-menu-item-group-title {
      display: none;
    }

    .ant-menu-item,
    .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item {
      padding: 0 14px;
    }
  }
`;

export const SidebarLogo = styled.div`
  height: 46px;
  line-height: 46px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.02);

  img {
    height: 30px;
  }
`;
