import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { Button, Dropdown, Menu, message, Modal, Input, Checkbox } from 'antd';
import * as Icons from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

import { NextTableContext } from '../../../shared/modules/next-table/types';
import { nextTableColumns } from '../../../shared/modules/next-table/helpers/next-table-columns';
import { useSearchParams } from '../../../shared/hooks/use-search-params';

import { CustomersTableContext } from '../context';
import { Customer } from '../types';
import { localURLMaker } from '../../../shared/utils/url-maker';
import { StopPropagation } from '../../../shared/components/stop-propagation';
import { CustomersService } from '../services';
import { MeContext } from '../../me/context/context';

export const useCustomersTable = () => {
  const { state, handleFetch } = useContext<NextTableContext>(CustomersTableContext);
  const history = useHistory();
  const location = useLocation();
  const { can } = useContext(MeContext);

  const getRowProps = useCallback(
    (id) => ({
      onDoubleClick: () => history.push(localURLMaker('/customers/:id/details', { id }), { background: location }),
    }),
    [history, location],
  );

  const columns = useMemo<Column<Customer>[]>(
    () => [
      {
        ...nextTableColumns.actions,
        Cell: ({ row: { original } }) => {
          const goTo = useCallback(
            (type: string) => {
              history.push(localURLMaker('/customers/:id/:type', { id: original.id, type }));
            },
            [original.id],
          );

          const handleAction = useCallback(
            async (actionType) => {
              const handler = async () => {
                message.loading({ key: 'message', content: 'Əməliyyat aparılır.' });
                const result = await CustomersService.actionByIds([original.id], actionType);

                if (result.status === 200) {
                  message.success({ key: 'message', content: 'Əməliyyat müvəffəqiyyətlə başa çatdı' });
                  history.push(localURLMaker('/customers', {}, { reFetchCustomersTable: true }));
                } else {
                  message.error({ key: 'message', content: result.data as string });
                }
              };

              Modal.confirm({
                title: 'Əməliyyatı etməyinizə əminsinizmi?',
                content: 'Əməliyyatı etdikdən sonra məlumatların geri qaytarılması mümkün olmayacaq.',
                onOk: handler,
              });
            },
            [original.id],
          );

          const overlay = (
            <Menu>
              {can(36) && (
                <Menu.Item onClick={() => goTo('update')} icon={<Icons.EditOutlined />}>
                  Dəyişdir
                </Menu.Item>
              )}
              {can(33) && (
                <Menu.Item onClick={() => goTo('details')} icon={<Icons.FileTextOutlined />}>
                  Ətraflı
                </Menu.Item>
              )}
              <Menu.Divider />
              {can(37) && (
                <Menu.Item onClick={() => handleAction('remove')} icon={<Icons.DeleteOutlined />}>
                  Sil
                </Menu.Item>
              )}
            </Menu>
          );

          return (
            <StopPropagation>
              <Dropdown overlay={overlay}>
                <Button size='small' icon={<Icons.MoreOutlined />} />
              </Dropdown>
            </StopPropagation>
          );
        },
      },
      {
        ...nextTableColumns.smaller,
        accessor: (row) => row.id,
        id: 'id',
        Header: 'Kod',
      },
      {
        accessor: (row) => row.firstname,
        id: 'name',
        Header: 'Ad',
      },
      {
        accessor: (row) => row.lastname,
        id: 'surname',
        Header: 'Soyad',
      },
      {
        accessor: (row) => row.patronymic,
        id: 'patronymic',
        Header: 'Ata adı',
      },
      {
        accessor: (row) => row.passport.secret,
        id: 'pin_number',
        Header: 'Fin kod',
        Filter: ({ setFilter, state: { filters } }) => {
          const passportSecret = filters.find(({ id }) => id === 'pin_number')?.value || '';
          const passportSecretExists = filters.find(({ id }) => id === 'pin_number_exists')?.value !== '0';

          const [focused, setFocused] = useState(false);
          const [currentValue, setCurrentValue] = useState(passportSecret);

          const handleKeyUp = useCallback(
            (e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                setFilter('pin_number', currentValue);
              }
            },
            [setFilter, currentValue],
          );

          useEffect(() => {
            setCurrentValue(passportSecret);
          }, [passportSecret]);

          const handleChange = useCallback((e) => {
            setCurrentValue(e.target.value);
          }, []);

          const handleFocus = useCallback(() => {
            setFocused(true);
          }, []);

          const handleBlur = useCallback(() => {
            setFocused(false);
          }, []);

          return (
            <Input
              value={focused ? currentValue : passportSecret}
              onFocus={handleFocus}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              onBlur={handleBlur}
              disabled={!passportSecretExists}
              suffix={
                <Checkbox
                  checked={passportSecretExists}
                  onChange={(event) => {
                    setFilter('pin_number', undefined);
                    setFilter('pin_number_exists', event.target.checked ? undefined : '0');
                  }}
                />
              }
            />
          );
        },
      },
      {
        accessor: (row) => row.phoneNumber,
        id: 'number',
        Header: 'Telefon nömrəsi',
      },
      {
        ...nextTableColumns.date,
        accessor: (row) => row.createdAt,
        id: 'created_at',
        Header: 'Qeydiyyat tarixi',
      },
      {
        id: 'pin_number_exists',
      },
    ],
    [can, history],
  );

  const { searchParams, remove } = useSearchParams<{ reFetchCustomersTable?: string }>();

  useEffect(() => {
    (async () => {
      if (searchParams.reFetchCustomersTable) {
        remove.current('reFetchCustomersTable');
        await handleFetch();
      }
    })();
  }, [handleFetch, remove, searchParams.reFetchCustomersTable, state.filters, state.pageIndex, state.pageSize, state.sortBy]);

  return { columns, getRowProps };
};
