import React, { useContext } from 'react';
import { Menu, Tooltip } from 'antd';
import * as Icons from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

import { SidebarLogo, StyledSider } from '../styled';
import { useSidebar } from '../hooks';
import { MeContext } from '../../me/context/context';
import { CashRegisterStatusContext } from '../../cash-register/context/status';
import logo from '../../../shared/assets/logo.png';
export const AppSidebar = () => {

  const { isOpen, activeKey } = useSidebar();
  const { can, state } = useContext(MeContext);
  const cashRegisterStatus = useContext(CashRegisterStatusContext);

  return (
    <StyledSider trigger={null} collapsible={true} width={224} collapsedWidth={46} collapsed={!isOpen}>
      <SidebarLogo>
        <img src={logo} alt="ArtroMed Logo" />
      </SidebarLogo>
      <Menu theme='light' mode='inline' selectedKeys={[activeKey]}>
        <Menu.ItemGroup key='statistics' title='Ümumi'>
          <Menu.Item key='/statistics' icon={<Icons.RocketOutlined />}>
            Piştaxta
            <NavLink to='/statistics' />
          </Menu.Item>
          {can(33) && (
            <Menu.Item key='/customers' icon={<Icons.TeamOutlined />}>
              Müştərilər
              <NavLink to='/customers' />
            </Menu.Item>
          )}
          {(can(1) && !state.user?.data?.emergencyMode) && (
            <Menu.Item key='/users' icon={<Icons.UserSwitchOutlined />}>
              İstifadəçilər
              <NavLink to='/users' />
            </Menu.Item>
          )}
        </Menu.ItemGroup>
        <Menu.ItemGroup key='cash_register' title='Kassa'>
          {can(77) && !cashRegisterStatus.data?.connected && (
            <Menu.Item disabled={true} key='/cash_register' icon={<Icons.MoneyCollectOutlined />}>
              <Tooltip title='Kassa əməliyyatları üçün ekranın yuxarı hissəsində olan "Obyekt seç" düyməsindən istifadə etməklə obyektinizi seçin'>
                Kassa aparatı
                <NavLink to='/cash_register' />
              </Tooltip>
            </Menu.Item>
          )}
          {can(77) && cashRegisterStatus.data?.connected && (
            <Menu.Item key='/cash_register' icon={<Icons.MoneyCollectOutlined />}>
              Kassa aparatı
              <NavLink to='/cash_register' />
            </Menu.Item>
          )}
          {can(94) && (
            <Menu.Item key='/cash_register/checks' icon={<Icons.FileExclamationOutlined />}>
              Çap edilmiş çeklər
              <NavLink to='/cash_register/checks' />
            </Menu.Item>
          )}
          {(can(109) && !state.user?.data?.emergencyMode) && (
            <Menu.Item key='/cash-flow' icon={<Icons.FileExclamationOutlined />}>
              Cashflow
              <NavLink to='/cash-flow' />
            </Menu.Item>
          )}
          {(can(71) && !state.user?.data?.emergencyMode) && (
            <Menu.Item key='/transactions' icon={<Icons.TransactionOutlined />}>
              Tranzaksiyalar
              <NavLink to='/transactions' />
            </Menu.Item>
          )}
        </Menu.ItemGroup>
        <Menu.ItemGroup key='appointments' title='Qəbul'>
          {can(66) && (
            <Menu.Item key='/appointments/create' icon={<Icons.TeamOutlined />}>
              Qəbula yazılmaq
              <NavLink to='/appointments/create' />
            </Menu.Item>
          )}
          {can(64) && (
            <Menu.Item key='/appointments' icon={<Icons.TableOutlined />}>
              Qəbul reyestr
              <NavLink to='/appointments' />
            </Menu.Item>
          )}
          {can(64) && (
            <Menu.Item key='/insurances' icon={<Icons.PaperClipOutlined />}>
              Sığorta şirkətləri
              <NavLink to='/insurances' />
            </Menu.Item>
          )}
          {can(64) && (
            <Menu.Item key='/appointments/calendar' icon={<Icons.CalendarOutlined />}>
              Qəbul təqvimi
              <NavLink to='/appointments/calendar' />
            </Menu.Item>
          )}
        </Menu.ItemGroup>
        <Menu.ItemGroup key='catalogs' title='Kataloqlar'>
          {can(58) && (
            <Menu.Item key='/doctors' icon={<Icons.UsergroupAddOutlined />}>
              Həkimlər
              <NavLink to='/doctors' />
            </Menu.Item>
          )}
          {can(8) && (
            <Menu.Item key='/clinics' icon={<Icons.ClusterOutlined />}>
              Partnyor klinikalar
              <NavLink to='/clinics' />
            </Menu.Item>
          )}
          {can(38) && (
            <Menu.Item key='/products' icon={<Icons.OrderedListOutlined />}>
              Məhsullar
              <NavLink to='/products' />
            </Menu.Item>
          )}
          {can(48) && (
            <Menu.Item key='/services' icon={<Icons.SortAscendingOutlined />}>
              Xidmətlər
              <NavLink to='/services' />
            </Menu.Item>
          )}
        </Menu.ItemGroup>
        <Menu.ItemGroup key='settings' title='Proqram ayarları'>
          {can(18) && (
            <Menu.Item key='/status' icon={<Icons.BuildOutlined />}>
              Statuslar
              <NavLink to='/status' />
            </Menu.Item>
          )}
          {can(13) && (
            <Menu.Item key='/models' icon={<Icons.AppstoreOutlined />}>
              Modellər
              <NavLink to='/models' />
            </Menu.Item>
          )}
          {(can(28) && !state.user?.data?.emergencyMode) && (
            <Menu.Item key='/groups' icon={<Icons.GroupOutlined />}>
              Qruplar
              <NavLink to='/groups' />
            </Menu.Item>
          )}
          {can(23) && (
            <Menu.Item key='/operations' icon={<Icons.SettingOutlined />}>
              Əməliyyatlar
              <NavLink to='/operations' />
            </Menu.Item>
          )}
        </Menu.ItemGroup>
      </Menu>
    </StyledSider>
  );
};
