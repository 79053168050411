import { useQuery } from '../../../shared/modules/use-query/hooks';
import { useCallback, useMemo } from 'react';
import { RegionsService } from '../services';
import { IRegion } from '../types';

export const useGetRegions = () => {
    return useQuery<IRegion[]>(
        useCallback(async () => {
            const result = await RegionsService.getAll({ page: 1, per_page: 5000 });
            if (result.status === 200) {
                return result.data;
            } else {
                return [];
            }
        }, []),
        useMemo(() => ({ initialData: [] }), []),
    );
};
