import React, { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import { CashRegisterStatus } from '../types';
import { CashRegisterService } from '../services';
import { MeContext } from '../../me/context/context';

export const CashRegisterStatusContext = createContext<{
  data: CashRegisterStatus | null;
  loading: boolean;
  error: null | string;
  reFetch: () => void;
}>({
  data: null,
  loading: false,
  error: null,
  reFetch: () => { },
});

export const CashRegisterStatusProvider: FC = ({ children }) => {
  const { can } = useContext(MeContext);
  const [statusEnabled, setStatusEnable] = useState<boolean>(true);

  const canGetStatus = useMemo(() => can(97), [can]);

  const status = useQuery<CashRegisterStatus | null>(
    useCallback(async () => {
      const result = await CashRegisterService.getPrinterIsConnected();

      if (result.status === 200) {
        setStatusEnable(true);
        return result.data;
      } else if (result.status === 403) {
        setStatusEnable(false);
        throw new Error('no_chosen_device');
      } else {
        setStatusEnable(true);
        throw new Error(result.data);
      }
    }, []),
    useMemo(() => ({ initialData: null, polling: 30000, enabled: canGetStatus && statusEnabled }), [canGetStatus, statusEnabled]),
  );

  const providerValue = useMemo(
    () => ({
      data: status.data,
      loading: status.loading,
      error: status.error,
      reFetch: status.reFetch,
    }),
    [status.data, status.loading, status.error, status.reFetch],
  );

  return <CashRegisterStatusContext.Provider value={providerValue}>{children}</CashRegisterStatusContext.Provider>;
};
