import React, { lazy, Suspense, useContext } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { SuspenseSpin } from '../shared/styled/spin';

import { AppLayout } from '../modules/layout/containers';
import { IMeContext } from '../modules/me/context/types';
import { MeContext } from '../modules/me/context/context';
import StatisticsModule from '../modules/statistics';
import { CashRegisterStatusProvider } from '../modules/cash-register/context/status';
import { SimilarUsers } from '../modules/appointments/containers/found-users';
import { CombineCustomers } from '../modules/customers/containers/combine-customers';
import { CustomersModalRouter } from '../modules/customers/router';
import { CashboxesRouter } from '../modules/cash-flow/cash-flow-overview/router';

const MeModule = lazy(() => import('../modules/me'));
const StatusModule = lazy(() => import('../modules/status'));
const ModelsModule = lazy(() => import('../modules/models'));
const UsersModule = lazy(() => import('../modules/users'));
const ClinicsModule = lazy(() => import('../modules/clinics'));
const DoctorsModule = lazy(() => import('../modules/doctors'));
const CustomersModule = lazy(() => import('../modules/customers'));
const OperationsModule = lazy(() => import('../modules/operations'));
const GroupsModule = lazy(() => import('../modules/groups'));
const InsurancesModule = lazy(() => import('../modules/insurances'));
const ProductsModule = lazy(() => import('../modules/products'));
const ServicesModule = lazy(() => import('../modules/services'));
const AppointmentsModule = lazy(() => import('../modules/appointments'));
const TransactionsModule = lazy(() => import('../modules/transactions'));
const CashRegisterModule = lazy(() => import('../modules/cash-register'));
const CashboxesModule = lazy(() => import('../modules/cash-flow/cashboxes'));
const CashCategoriesModule = lazy(() => import('../modules/cash-flow/categories'));
const CashflowTransactionsModule = lazy(() => import('../modules/cash-flow/transactions'));
const CashflowAnaliticsModule = lazy(() => import('../modules/cash-flow/analytics/pages/cash-flow-analtyics.page'));

export const MainRouter = () => {
  const me = useContext<IMeContext>(MeContext);
  const location = useLocation<{ background?: any }>();

  if (me.state.user.loading) {
    return <SuspenseSpin />;
  }

  if (!!me.state.user.data) {
    return (
      <CashRegisterStatusProvider>
        <AppLayout>
          <Suspense fallback={<SuspenseSpin />}>
            <Switch location={location?.state?.background || location}>
              <Route path='/status' component={StatusModule} />
              <Route path='/models' component={ModelsModule} />
              <Route path='/users' component={UsersModule} />
              <Route path='/clinics' component={ClinicsModule} />
              <Route path='/doctors' component={DoctorsModule} />
              <Route path='/customers' component={CustomersModule} />
              <Route path='/operations' component={OperationsModule} />
              <Route path='/groups' component={GroupsModule} />
              <Route path='/products' component={ProductsModule} />
              <Route path='/insurances' component={InsurancesModule} />
              <Route path='/services' component={ServicesModule} />
              <Route path='/appointments' component={AppointmentsModule} />
              <Route path='/transactions' component={TransactionsModule} />
              <Route path='/cash_register' component={CashRegisterModule} />
              <Route path='/cash-flow/cashboxes' component={CashboxesModule} />
              <Route path='/cash-flow/cash-categories' component={CashCategoriesModule} />
              <Route path='/cash-flow/transactions' component={CashflowTransactionsModule} />
              {me.can(118) && <Route path='/cash-flow/analytics' component={CashflowAnaliticsModule} />}
              <Route path='/cash-flow' component={CashboxesRouter} />
              <Route path='/statistics' component={StatisticsModule} />
              <Redirect to='/statistics' />
            </Switch>
            <Switch>
              <Route path='/customers' component={CustomersModalRouter} />
              <Route path='/similar-users' component={SimilarUsers} />
              <Route path='/customers/combine' component={CombineCustomers} />
            </Switch>
          </Suspense>
        </AppLayout>
      </CashRegisterStatusProvider>
    );
  }

  return (
    <Suspense fallback={<SuspenseSpin />}>
      <Switch>
        <Route path='/' component={MeModule} />
      </Switch>
    </Suspense>
  );
};
