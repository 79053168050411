export const Theme = {
  colors: {
    primary: '#0A4755',
    secondary: '#fafafa',
    ternary: '#02B25F',
    danger: '#ff4d4f',
    success: '#73d13d',
    white: '#ffffff',
    black: '#000000',
    blue: '#061f4c',
  },
  shadows: {
    base: '0 6px 12px rgba(0, 0, 0, 0.1)',
    strong: '0 12px 24px rgba(0, 0, 0, 0.1)',
    soft: '0 12px 24px rgba(0, 0, 0, 0.03)',
  },
}
